import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/smart-components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  snackBar = inject(MatSnackBar);

  private showAlert(
    messageKey: string,
    messageType: 'success' | 'error' | 'warning' | 'information'
  ): void {
    const config: MatSnackBarConfig = {
      data: { messageKey, messageType },
      panelClass: [`supra-snack-bar`],
      duration: 3000,
    };
    this.snackBar.openFromComponent(SnackbarComponent, config);
  }

  success(message = 'alert.success'): void {
    this.showAlert(message, 'success');
  }

  error(message = 'alert.error'): void {
    this.showAlert(message, 'error');
  }

  warning(message = 'alert.warning'): void {
    this.showAlert(message, 'warning');
  }

  information(message = 'alert.information'): void {
    this.showAlert(message, 'information');
  }
}
