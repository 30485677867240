<div
  matSnackBarLabel
  data-testid="snackbar-container"
  [ngClass]="['app-snack-bar__content', 'app-snack-bar--' + data.messageType]">
  <span data-testid="snackbar-message">{{ data.messageKey | translate }}</span>
  <button
    mat-icon-button
    (click)="closeSnackBar()"
    [attr.aria-label]="'close' | translate">
    <mat-icon>close</mat-icon>
  </button>
</div>
