/* src/app/shared/components/smart-components/snackbar/snackbar.component.scss */
.app-snack-bar__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 12px;
  border-radius: 5px;
}
.app-snack-bar--success {
  background-color: #047647;
}
.app-snack-bar--error {
  background-color: #D92D21;
}
.app-snack-bar--warning {
  background-color: #F69007;
}
.app-snack-bar--information {
  background-color: #296DEA;
}
.app-snack-bar button {
  color: #ffffff;
}
/*# sourceMappingURL=snackbar.component.css.map */
